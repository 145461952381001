.portfolio-list {
  --portfolio-columns: 7fr repeat(2, 3fr) 2.5fr 3fr 4fr;

  .no-projects-tile.hidden {
    display: none;
  }

  &__body {
    &:empty + .no-projects-tile.hidden {
      display: flex;
    }

    & > .loader {
      display: block;
      margin: 35px auto 0;
    }
  }

  &__header {
    list-style: none;
    padding: 16px 40px;
    margin: 0;
    display: grid;
    grid-gap: 18px;
    grid-template-columns: var(--portfolio-columns);

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      line-height: 1.26;
      font-weight: 500;
      color: var(--color-main-grey);

      &:not(:first-child) {
        justify-content: center;
      }
    }
  }

  & .portfolio-item {
    margin-bottom: 16px;
  }
}

