.kyc-badge {
  &__wallet {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    height: 24px;

    .copiable {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      letter-spacing: 0.5px;

      svg path {
        fill: var(--color-main-yellow);
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__status {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.26;
  }

  &__message {
    margin: 0;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  &__action {
    flex-shrink: 0;
  }
}

.kyc-approved-mark {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 20px 40px;
  border: 1px solid var(--color-main-yellow);

  svg {
    margin-right: 12px;

    path {
      fill: var(--color-main-yellow);
    }
  }
}

.kyc-badge-line {
  background: var(--color-main-dark);
  width: 100vw;
}

.kyc-badge-row {
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
}
