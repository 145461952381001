@import "src/styles/mixins";
@import "src/styles/variables";

.portfolio-item {
  &.tile {
    padding: 40px 40px 16px;
    position: relative;
  }

  &__main {
    display: grid;
    grid-template-columns: var(--portfolio-columns);
    grid-gap: 18px;
    padding-bottom: 24px;
  }

  &__col {
    display: flex;
    align-items: center;
    $col: &;

    &:not(:first-child, :last-child) {
      justify-content: center;
    }

    &--action {
      justify-content: flex-end;

      .round-button:first-child {
        flex-grow: 1;
      }
    }

    & > span {
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    span:empty {
      position: relative;;
    }

    span:empty:before {
      content: '–';
    }

    h4 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .round-button {
      white-space: nowrap;
      font-family: var(--extra-font);
      font-weight: 600;
    }

    &--wrong-network {
      display: grid;
      padding-left: 32px;
      grid-column-start: 2;
      grid-column-end: 7;
      font-size: 18px;
    }
  }

  &__col,
  &__additions {
    @include gap(16px);
  }

  &__name a {
    color: var(--color-light-yellow);
    text-decoration: none;

    &:hover {
      color: var(--color-main-yellow);
    }
  }

  &__logo {
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 8px;
    text-align: center;
    flex-shrink: 0;
  }

  &__logo-image {
    width: 100%;
    height: 100%;
  }

  &__network-image {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &__toggle {
    &.round-button {
      padding-left: 13px;
      padding-right: 13px;
      @include gap(12px);
    }

    svg {
      padding: 0;
      width: 10px;
      transition: .2s linear;
      transform-origin: center;
    }

    &.active svg {
      transform: rotate(180deg);
    }
  }

  &__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    p {
      margin-right: 56px;
    }
  }

  &__additions {
    display: flex;
    flex-basis: 70%;
    margin-bottom: 0;
    justify-content: center;

    div {
      border: 1px solid rgba($color-light-yellow, 0.5);
      border-radius: 16px;
      text-align: center;
      padding: 14px 16px;
      max-width: 248px;
      width: 100%;
      line-height: 1.26;
      letter-spacing: 1px;
    }

    dt {
      font-weight: 500;
      color: var(--color-main-grey);
      margin-bottom: 4px;
    }

    dd {
      font-weight: 600;
      margin-bottom: 0;
    }
  }


  &__footer {
    text-align: center;

    &:not(:empty) {
      border-top: 1px solid var(--color-separator);
      padding: 24px 0;
    }
  }

  &__schedule {
    a {
      display: inline-flex;
      text-decoration: none;
      color: var(--color-light-yellow);

      &:hover {
        color: var(--color-main-yellow);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      path {
        fill: currentColor;
      }
    }
  }

  .round-button {
    letter-spacing: 1px;
  }
}
