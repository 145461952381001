.tooltip-wrapper {
  cursor: pointer;
}

.common-tooltip .tooltip-inner {
  font-family: var(--base-font);
  background-color: var(--color-medium-black);
  box-shadow: 4px 2px 30px #101025;
  border-radius: 16px;
  padding: 24px;
  max-width: 450px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;

  svg path {
    fill: var(--color-main-grey);
  }

  .divider {
    background-color: #3A4259;
    border-color: #3A4259;
  }
}

.common-tooltip.tooltip.show {
  opacity: 1;
}
