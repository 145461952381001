@import "src/styles/mixins";

.project-filter {
    --list-font-size: 16px;

    &__name {
        margin-right: 8px;
        font-size: calc(var(--list-font-size) * 1.25);
        color: var(--color-text-white-transparantize);
    }

    &__toggle {
        img {
            margin-right: 10px;
        }
    }

    &__toggle.round-button {
        line-height: 42px;
        border-radius: 50px;
    }

    &__menu.dropdown-menu {
        @include blurred-dark-background(200px, absolute);
        border-radius: 16px;
        top: calc(100% + 12px);
        z-index: 2;
    }

    &__item {
        &.dropdown-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: var(--color-text-white);
            padding: 0.65rem 1rem;
            font-weight: 500;
            letter-spacing: 1px;
            font-size: var(--list-font-size);
            text-transform: uppercase;

            &:hover,
            &.active,
            &:active {
                background-color: inherit;
                color: var(--color-text-violet);
            }
        }

        img {
            margin-right: 10px;
        }
    }
}
