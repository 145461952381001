.network-switcher__dropdown {
    border-radius: 25px;
}

.network-switcher__dropdown.disabled {
    opacity: 0.4;
    pointer-events: none;
}


.network-switcher__dropdown .network-switcher__toggle,
.network-switcher__dropdown:not(.show)>.network-switcher__toggle:focus {
    display: flex;
    align-items: center;
    color: inherit;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 2px 11px;
}

.network-switcher__dropdown.single .network-switcher__toggle {
    pointer-events: none;
    cursor: default;
}

.network-switcher__dropdown.single .network-switcher__toggle:after {
    content: none;
}

.network-switcher__icon {
    height: 32px;
    border-radius: 50%;
    width: auto;
}

.network-switcher__dropdown .network-switcher__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
}

.network-switcher__item .network-switcher__icon {
    margin-right: 10px;
}

.network-switcher__dropdown .network-switcher__item.selected {
    pointer-events: none;
    font-weight: 700;
}
