@import "src/styles/mixins";

.top-banner {

  &__doge {
    left: 42%;
    bottom: 60px;
    position: absolute;
  }

  &__microwave {
    top: 25%;
    right: 21%;
    width: 15%;
    position: absolute;

    @include media-breakpoint-down(1850px) {
      right: 20%;
      top: 26%;
    }

    @include media-breakpoint-down(1800px) {
      right: 19%;
      top: 27%;
    }

    @include media-breakpoint-down(1750px) {
      right: 18%;
      top: 28%;
    }

    @include media-breakpoint-down(1700px) {
      right: 17%;
      top: 29%;
    }

    @include media-breakpoint-down(1650px) {
      right: 16%;
      top: 30%;
    }

    @include media-breakpoint-down(1600px) {
      right: 15%;
    }

    @include media-breakpoint-down(1450px) {
      right: 14%;
    }
  }
}
