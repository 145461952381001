@import "src/styles/mixins";

.project-page {
  padding: 50px 0;

  .project-info {
    top: 15px;
    position: sticky;
  }

  .back-link {
    margin-bottom: 36px;
    padding-left: 0;

    svg path {
      fill: var(--color-main-yellow);
    }
  }

  & > .loader {
    display: block;
    margin: 0 auto;
  }

  &__wallet-info {
    margin-bottom: 40px;

    @include media-breakpoint-down(lg) {
      padding-left: 44px;
      margin-bottom: 0;
    }
  }
}

.details-section {
  &__warning-message {
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--color-text-red);
  }
}

.swap-section {
  margin-top: 32px;
  padding-bottom: 50px;
}

.whitelist-block {
  margin-top: 40px;

  .loader {
    margin: auto;
  }

  .round-button {
    width: 100%;
  }

  a:not(.round-button) {
    color: inherit;

    &:hover {
      color: var(--color-text-white);
    }
  }
}

.whitelist-badge {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.01em;
  padding: 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--color-medium-black);
  border-radius: 16px;

  &.invalid,
  &.rejected,
  &.not_submitted {
    border: none;
    background-color: var(--color-warning-bg);
  }

  &.passed {
    color: var(--color-main-yellow);
    background: rgba(255, 204, 126, 0.16);
  }

  .loader {
    --loader-size: 32px;
  }

  img {
    margin-right: 16px;
  }
}

.tier-status {
  font-size: 20px;
  line-height: 1.26;
  letter-spacing: 1px;
  margin-bottom: 16px;
}

.tier-status span {
  font-weight: 700;
  font-style: italic;
}

.swap-form {
  border-radius: 24px;
  padding: 40px;

  @include media-breakpoint-down(xl) {
    padding: 24px;
  }

  .balance-item {
    &__icon {
      width: 72px;
      height: 72px;
    }
  }

  .form-message {
    margin-bottom: 24px;
  }

  .input-group {
    margin-bottom: 24px;

    .form-control {
      font-size: 24px;
    }

    img {
      width: 32px;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    margin: 0 0 5px;
    @include gap(16px);

    .round-button {
      flex-basis: 50%;
      flex-grow: 1;
      margin: 0;
    }

    svg path {
      fill: var(--color-text-black);
    }
  }
}

.external-description {
  &__nft-image {
    width: 100%;

    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 24px;

      @include media-breakpoint-down(xl) {
        max-height: 600px;
      }
    }
  }

  &__nft-subtitle {
    font-size: 40px;
    font-weight: 600;
  }
}

.claim-section {
  margin-top: 32px;
}

:is(.claim-section, .swap-section) .title {
  font-weight: 600;
  font-size: 32px;
  line-height: 126%;
  margin-bottom: 24px;
}
