.simple-table {
    width: 100%;
    border-collapse: collapse;
}

.simple-table__empty-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}
