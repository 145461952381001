.timer-values {
    display: flex;
    align-items: center;
    justify-content: center;
    --highlight-color: var(--color-main-yellow)
}

.timer-values__item {
    margin: 0 0.8rem;
}

.timer-values__item .value {
    font-size: 30px;
    font-weight: 600;
    line-height: 125%;
    color: var(--highlight-color);
    text-align: center;
}

.timer-values__item.seconds .value {
    color: var(--color-main-grey);
}

.timer-values__item .name {
    line-height: 144%;
    text-align: center;
    text-transform: capitalize;
}
