$screen-xxs: 400px;
$screen-xs: 460px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

$grid-breakpoints: (
        xxs: $screen-xxs,
        xs: $screen-xs,
        sm: $screen-sm,
        md: $screen-md,
        lg: $screen-lg,
        xl: $screen-xl,
        xxl: $screen-xxl,
);

$color-main-yellow: #FFCC7E;
$color-light-yellow: #FFEACB;
$color-light-black: #33334A;
$color-main-grey: #A9AAB7;

:root {
  --color-text-white: #FFFFFF;
  --color-text-black: #1E1E2F;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-background: #0F0F1E;

  --color-main-dark: #1E1E2F;
  --color-light-black: #{$color-light-black};
  --color-medium-black: #25253C;

  --color-light-yellow: #{$color-light-yellow};
  --color-main-yellow: #{$color-main-yellow};

  --color-main-grey: #{$color-main-grey};

  --color-warning-border: #722D39;
  --color-warning-bg: rgba(234, 68, 98, 0.2);

  --color-text-red: #FE4646;
  --color-text-yellow: #FFBB55;
  --color-text-success: #02C88D;
  --color-text-violet: #C58BFF;
  --color-main-gradient-text: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);

  --color-separator: var(--color-light-black);
  --color-separator-transparentize: var(--color-main-grey);
  --container-max-width: 100%;

  --tile-padding: 40px;
  --tile-background-color: var(--color-main-dark);
  --tile-active-background-color: var(--color-light-black);

  --base-font: 'Plus Jakarta Sans', sans-serif;
  --extra-font: 'Clash Display', sans-serif;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) - 12px)
}
