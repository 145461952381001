@import "src/styles/mixins";

.faq-page .marketing-content {
  margin-top: 80px;
  margin-bottom: 200px;
}

.faq-page .card.accordion {
  padding-top: 25px;
  border-bottom: 1px solid var(--color-light-black);
  border-radius: 0;
}

.faq-page .card.accordion .card-header {
  padding-bottom: 25px;
  position: relative;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    max-height: 80px;
  }
}

.faq-page .card.accordion .card-body {
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .5px;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

.faq-page .card.accordion .btn {
  font-size: 24px;
  padding: 0;
  text-align: left;
  letter-spacing: 1px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.faq-page .card.accordion svg {
  @include media-breakpoint-down(sm) {
    width: 13px;
  }
}
