@import 'src/styles/mixins';

.mobile-screen {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/background-square.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__header {
    display: flex;
    margin-top: 15px;
    justify-content: center;
  }

  &__content {
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 100px);

    .round-button {
      margin-top: 30px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-light-yellow);
    font-family: var(--base-font);
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    max-width: 540px;
  }
}
