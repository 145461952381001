@import "src/styles/mixins";

.project-info {

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  &__logo {
    width: 140px;
    height: 140px;
    margin-right: 24px;
    object-fit: cover;
    object-position: center;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
    @include gap(8px)
  }

  &__name {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-light-yellow);
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 8px;
  }

  &__description {
    line-height: 1.5;
    letter-spacing: 0.5px;
  }

  &__download-terms {
    display: block;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.5px;
    margin: 32px 0;
  }

  &__links {
    margin-top: 24px;
  }

  &__links-title {
    margin-bottom: 16px;
  }

  &__links-list {
    display: flex;
    align-items: center;
    @include gap(16px);

  }

  &__link {
    $size: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $size;
    height: $size;
    font-size: 24px;
    background: var(--color-light-black);
    color: var(--color-light-yellow);
    border: 1px solid currentColor;
    border-radius: 12px;
    transition: .2s linear;

    &.round-button svg {
      width: 1.35em;
      height: 1.35em;

      path {
        fill: currentColor;
      }
    }

    &:hover {
      color: var(--color-text-white);
    }
  }

  @include media-breakpoint-down(xl) {
    &__head {
      margin-bottom: 24px;
    }

    &__logo {
      width: 100px;
      height: 100px;
      margin-right: 16px;
    }

    &__name {
      font-size: 32px;
    }
  }
}
