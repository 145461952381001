.timezone-switcher {
  border-radius: 12px;
  padding: 4px;
  background-color: var(--color-medium-black);

  input {
    appearance: none;
  }

  .timezone-option {
    position: relative;
    display: inline-block;
    min-width: 132px;
    padding: 11px 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.15s linear;
    z-index: 0;

    &.active {
      color: var(--color-main-yellow);
      font-weight: 700;
    }

    &:first-child {
      &:before {
        position: absolute;
        content: '';
        left: calc(100% + 4px);
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: var(--color-light-black);
        transition: 0.15s linear;
        z-index: -1;
      }

      &.active:before {
        left: 0;
      }
    }
  }

  label + label {
    margin-left: 4px;
  }
}
