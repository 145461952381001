@import 'src/styles/mixins';

.advantages-section {
  margin-bottom: 200px;

  @include media-breakpoint-down(lg) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.advantages-section__buttons {
  display: flex;
  margin-top: 38px;
  width: 100%;
  @include gap(16px);

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.advantages-section__buttons-below {
  display: none;

  @include media-breakpoint-down(lg) {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.advantages-section__buttons-below .round-button {
  @include media-breakpoint-down(md) {
    width: 100%;
    line-height: 42px;
  }
}

.advantages-section__buttons-below .buy-flame-dropdown {
  margin-right: 25px;

  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.advantages-section__buttons-below .buy-flame-dropdown .round-button {
  @include media-breakpoint-down(md) {
    justify-content: center;
    padding: 0.375rem 0.75rem;
  }
}

.advantages-section__title {
  margin-bottom: 25px;

  @include media-breakpoint-down(sm) {
    font-size: 32px !important;
  }
}

.platform-advantages .advantages-item {
  margin-bottom: 40px;
}

.platform-advantages .advantages-item__title {
  font-size: 32px;
  margin-bottom: 8px;
  line-height: 40px;
  font-weight: 600;
  color: var(--color-light-yellow);

  @include media-breakpoint-down(sm) {
    font-size: 24px;
    margin-bottom: 12px;
  }
}

.platform-advantages .advantages-item__text {
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 400;
}
