.marketing-page {
    position: relative;
}

.marketing-page.page h1.title {
    margin-bottom: 16px;
}

.marketing-page .background .ellipse19 {
    top: -10%;
}

.marketing-page .background .ellipse20 {
    top: 20%;
}

.marketing-page .background .ellipse23 {
    top: 45%;
}

.form-fill-response {
    max-width: 604px;
    margin: 48px auto;
}

.form-fill-response h2 {
    margin-bottom: 24px;
}

.form-fill-response p {
    margin-bottom: 32px;
}

.marketing-page-form {
    height: 100%;
    width: 100%;
    max-width: 604px;
    margin: 80px auto;
}

.marketing-page-form.tile {
    padding: 40px 8px;
    border-radius: 32px;
}

.marketing-page-form__inner {
    max-height: 100%;
    padding: 0 26px 0 32px;
}

.marketing-page-form h2.title {
    margin-bottom: 24px;
}

.marketing-page-form h4.title {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 1.26;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.marketing-page-form .row + .row {
    margin-top: 32px;
}

.marketing-page-form .form-group {
    margin-bottom: 0;
}

.marketing-page-form .form-group:not(:last-child) {
    margin-bottom: 24px;
}

.marketing-page-form .form-group.form-group--radio:not(:last-child) {
    margin-bottom: 16px;
}

.marketing-page-form__submit-row .loader {
    vertical-align: middle;
}

.marketing-page-form .round-button {
    width: 100%;
}

.marketing-page__text p,
.marketing-page__text li {
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
}

.marketing-page__text a {
    color: var(--color-text-violet);
    text-decoration: none;
}

.marketing-page__text a:hover {
    color: var(--color-text-white-transparantize);
}

.marketing-page__text h2.title {
    margin-top: 40px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title {
    margin-top: 32px;
    margin-bottom: 16px;
}

.marketing-page__text h3.title em {
    color: var(--color-text-white-transparantize);
}
