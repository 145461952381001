@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;800&display=swap');
@import url('http://fonts.cdnfonts.com/css/clash-display');
@import "variables";
@import "mixins";

body {
  margin: 0;
  font-family: var(--base-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  min-height: 100%;
  color: var(--color-text-white);
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

header {
  z-index: 3;
}

.page {
  flex: 1 0;
}

section {
  position: relative;
}

.background {
  pointer-events: none;
}

section .background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.page h1.title {
  font-size: 64px;
  font-weight: 600;
  line-height: 112%;
  text-align: center;
  margin-bottom: 16px;
  color: var(--color-light-yellow);
  z-index: 1;
}

@media (max-width: 1199px) {
  .page h1.title {
    font-size: 52px;
  }
}

h2.title, h2.subtitle {
  font-size: 40px;
  font-weight: 600;
  line-height: 126%;
  color: var(--color-light-yellow);
}

a {
  color: var(--color-text-white);
  transition: 0.2s linear;

  &:hover {
    color: var(--color-light-yellow);
    text-decoration: none;
  }
}

.red-text {
  color: var(--color-text-red);
}

.success-text {
  color: var(--color-text-success);
}

.purple-text {
  color: var(--color-text-violet);
}

.white-transparent-text {
  color: var(--color-text-white-transparantize);
}

.yellow-text {
  color: var(--color-text-yellow);
}

.main-grey-text {
  color: var(--color-main-grey);
}

.light-yellow-text {
  color: var(--color-light-yellow);
}

.main-yellow-text {
  color: var(--color-main-yellow);
}

.main-grey-text {
  color: var(--color-main-grey);
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text--orange {
  background-image: linear-gradient(94.07deg, #FF6D43 2.02%, #FE5145 27.83%, #FF9B38 55.36%, #FE4B61 78.24%);
}

.gradient-text--purple {
  background-image: var(--color-main-gradient-text);
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.text-simple {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

.text-big {
  font-size: 20px;
  line-height: 1.26;
}

.text-wide {
  letter-spacing: 0.5px;
}

.bordered {
  border: 1px solid var(--color-text-white-transparantize);
}

.btn:focus {
  box-shadow: none !important;
}

.tile {
  padding: var(--tile-padding);
  background-color: var(--tile-background-color);
  backdrop-filter: blur(60px);
  border-radius: 24px;
  color: var(--color-text-white);
  box-shadow: 4px 2px 30px #101025;;

  &__description {
    color: var(--color-main-grey);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
  }

  &__main {
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    color: var(--color-light-yellow);
  }
}

.info-list {
  font-size: 16px;
  line-height: 1.26;
  font-weight: 500;
  letter-spacing: 0.5px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > div + div {
    margin-top: 16px;
  }

  dt {
    font-weight: 500;
    color: var(--color-main-grey);
  }

  dd {
    font-weight: 600;
    margin-bottom: 0;
  }
}

.input-group {
  display: flex;
  align-items: center;
  background: rgba($color-light-black, 0.5);
  border: 1px solid rgba($color-main-grey, 0.5);
  padding: 8px;
  border-radius: 8px;
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 24px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-control, .form-control:focus, .form-control:active {
  background: rgba($color-light-black, 0.5);
  border: 1px solid rgba($color-main-grey, 0.5);
  padding: 14px 16px;
  border-radius: 8px;
  box-shadow: none;
  color: var(--color-text-white);
}

.form-control[readonly] {
  background: rgba(255, 255, 255, 0.08);
  color: var(--color-main-grey);
}

.form-control::placeholder {
  color: var(--color-main-grey);
}

.form-check, .form-check-input, .form-check-label {
  cursor: pointer;
}

.form-check .invalid-feedback:empty {
  display: none;
}

.form-check {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.form-check-input {
  width: 18px;
  height: 18px;
  margin: 0 12px 0 0;
  background-color: var(--color-main-dark);
  border: 2px solid var(--color-light-yellow);
}

.form-check .form-check-input {
  float: none;
  flex-shrink: 0;
  margin: 0 12px 0 0;
  border-color: var(--color-light-yellow)
}

.form-check-input {
  border-color: var(--color-main-grey);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0 0.25rem var(--color-text-white-transparantize);
}

.form-check-input:checked[type="checkbox"] {
  background-color: transparent;
  border-color: var(--color-main-yellow);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url('../assets/checkbox-checked.svg');
}

.form-check-input:checked[type="radio"] {
  background: var(--color-main-yellow);
  background-clip: content-box;
  padding: 1px;
  border-color: var(--color-main-yellow);
}

.form-label {
  color: var(--color-main-grey);
  font-weight: 500;
}

.form-label, .form-check-label {
  line-height: 1.26;
  letter-spacing: 1px;
}

.input-group-prepend {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.input-group-prepend img:not(:last-child) {
  margin-right: 12px;
}

.input-group-prepend span {
  font-weight: 600;
  font-size: 20px;
  line-height: 126%;
  letter-spacing: 1px;
}

.input-group-append {
  margin-left: 12px !important;
}

.input-group .form-control {
  padding: 0 8px 0 0;
  background: transparent;
  border: none;
  backdrop-filter: none;
  text-align: right;
  line-height: 126%;
  appearance: none;
  -moz-appearance: textfield;
}

@media (max-width: 1399px) {
  .input-group-prepend span {
    font-size: 16px;
  }

  .input-group .form-control {
    font-size: 30px;
  }
}

.input-group .form-control::-webkit-outer-spin-button,
.input-group .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group .form-control::placeholder {
  color: var(--color-text-white-transparantize);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.custom-switch {
  --switch-size: 26px;
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.custom-switch .custom-control-label {
  background-color: var(--color-background);
  border: 2px solid var(--color-background);
  height: calc(var(--switch-size) + 4px);
  width: calc(var(--switch-size) * 2 - 2px);
  border-radius: calc(var(--switch-size) * 2 - 2px);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input {
  position: absolute;
  left: 2px;
  top: 2px;
  appearance: none;
  width: var(--switch-size);
  height: var(--switch-size);
  border-radius: 50%;
  background: #2D3449;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03);
  transition: 0.15s linear;
  cursor: pointer;
}

.custom-switch .custom-control-input:checked {
  left: calc(100% - 2px - var(--switch-size));
}

.custom-switch .custom-control-input:checked ~ .custom-control-label {
  background-color: var(--color-main-yellow);
  border-color: var(--color-main-yellow);
}

.form-message {
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.05px;
  transition: 0.15s linear;
  visibility: visible;
  opacity: 1;

  &.hidden {
    @include fadingHidden;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    path {
      fill: var(--color-text-white);
    }
  }

  &--warning {
    background-color: var(--color-warning-bg);
    border: 1px solid var(--color-warning-border);
    backdrop-filter: blur(20px);
    color: var(--color-text-white);
  }

  &--no-padding {
    padding: 0;
  }
}

.custom-alert {
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 1rem;
  letter-spacing: 1px;
  line-height: 1.4;
}

.custom-alert-dark {
  color: var(--color-text-white);
  border: 1px solid var(--color-light-black);
  background-color: var(--color-medium-black);
}

.navbar-inner {
  background: transparent;
}

.bn-notify-custom {
  transition: .2s linear;
}

.bn-notify-notification-link:hover {
  color: var(--color-text-white);
}

.bn-notify-notification-link:hover .bn-notify-notification-info-message {
  color: var(--color-light-yellow);
}

.custom-scroll {
  scrollbar-color: rgba($color-main-yellow, 0.1) transparent;
  scrollbar-width: 6px;
  overflow-scrolling: touch;
  overflow: auto;
  overscroll-behavior: none none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: rgba($color-main-yellow, 0.1);
    cursor: pointer;
    transition: .2s linear;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-main-yellow);
    border-radius: 24px;
    box-shadow: none;
    cursor: pointer;
    transition: .2s linear;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-light-yellow);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: darken($color-main-yellow, 10%);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.copiable {
  cursor: copy;
  transition: 0.15s linear;
}

.copiable:hover {
  background-color: rgba(255, 255, 2555, 0.3);
}

.add-network-button {
  white-space: nowrap;
}
