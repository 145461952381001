.whitelist-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 300px);

  .whitelist-title.title {
    margin-bottom: 32px;
    text-align: left;
  }

  .form-label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .form-check-label {
    letter-spacing: unset;
    font-weight: 500;
  }
}

.whitelist-page > .loader {
  display: block;
  margin: 0 auto;
}

.whitelist-section {
  padding-bottom: 80px;
  padding-top: 21px;

  .back-link {
    padding-left: 0;

    svg path {
      fill: var(--color-main-yellow);
    }
  }
}

.whitelist-section .ellipse18 {
  top: -20%;
}

.whitelist-section .ellipse19 {
  left: 10%;
  top: 40%;
}

.whitelist-section .tile + .tile {
  margin-top: 24px;
}

.whitelist-section .tile:not(.project-bio) p {
  margin-bottom: 0;
}

.whitelist-page .project-info {
  margin-bottom: 40px;
}

.whitelist-page .project-info__description {
  font-size: 14px;
}

.whitelist-page .kyc-approved-mark {
  padding: 14px;
}

.whitelist-time .title {
  margin-bottom: 24px;
}

.whitelist-time .info-list dt {
  letter-spacing: unset;
}

.whitelist-time .info-list dd {
  font-weight: 500;
}

.whitelist-section .tile:not(.whitelist-time, .whitelist-form, .wrong-network-tile, .kyc-badge) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 26px;
}

@supports (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form, .wrong-network-tile, .kyc-badge) {
    gap: 26px;
  }
}

@supports not (gap: 26px) {
  .whitelist-section .tile:not(.whitelist-time, .whitelist-form, .wrong-network-tile, .kyc-badge) > * + * {
    margin-left: 26px;
  }
}

.whitelist-section .round-button {
  margin: 0;
}

.wrong-network-tile .title {
  margin-bottom: 24px;
}

:is(.whitelist-tier) .round-button {
  width: 253px;
  flex-shrink: 0;
}

.whitelist-tier .balance-item__icon {
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  object-fit: contain;
  object-position: center;
}

@media (max-width: 1399px) {
  :is(.whitelist-tier) .round-button {
    width: 223px;
  }
}

.whitelist-nft-image {
  width: 100%;
}

.whitelist-nft-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 40px;
  border-radius: 32px;
}

@media (max-width: 1199px) {
  .whitelist-nft-image img {
    max-height: 600px;
  }
}

.whitelist-help {

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: var(--color-light-yellow);
  }

  .round-button {
    white-space: nowrap
  }
}
