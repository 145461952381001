.no-projects-tile {
  height: 400px;
  padding: 46px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;

  & .dots {
    width: 72px;
    height: 16px;
    margin: 0 auto 32px;
    background-image: linear-gradient(269.92deg, #FFCC7E 0.04%, rgba(255, 204, 126, 0) 105.45%);
  }

  .tile__description {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: var(--color-light-yellow);
    margin-bottom: 0;
    justify-content: center;
  }
}
