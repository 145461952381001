@import "src/styles/mixins";

.project-widget {
  transition: .2s linear;
  height: 100%;
  border: 1px solid transparent;

  &:hover {
    background-color: var(--tile-active-background-color);
    border-color: var(--project-main-color, var(--color-text-violet));

    .project-stats__info .separator {
      background-color: var(--tile-active-background-color);
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .project-info {
    margin-bottom: 28px;

    &__tags {
      margin-bottom: 8px;
    }

    &__subtitle {
      display: none;
    }

    &__description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 4.5em;
    }

    &__links-title {
      display: none;
    }

    &__link {
      $size: 40px;
      width: $size;
      height: $size;
      font-size: 24px;
    }
  }

  &__wrong-network-message {
    letter-spacing: 1px;
  }

  .project-stats {
    margin-bottom: auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @include gap(17px);

    a {
      flex-grow: 1;
    }

    .round-button {
      width: 100%;
      margin: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    &.tile {
      padding: 32px;
    }

    .project-info {
      margin-bottom: 24px;
    }

    &__buttons {
      margin-top: 24px;
    }
  }
}
