.card.accordion span {
    display: block;
}

.card.accordion {
    font-size: 18px;
    background: transparent;
    border-radius: 26px;
    text-align: left;
    border: none;
}

.card.accordion .collapse {
    padding-top: 0;
    line-height: 150%;
}

.card.accordion .card-header {
    border: 0;
    padding: 0;
    background-color: transparent;
}

.card.accordion .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100% !important;
    text-decoration: none;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--color-text-white);
    padding: 32px 36px;
}

.card.accordion .btn.active {
    color: var(--color-main-yellow);
}

.card.accordion .btn .minus,
.card.accordion .btn .plus {
    position: absolute;
    right: 0;
    top: 0;
}

.card.accordion .card-body {
    padding: 0 0 10px;
}

.card.accordion .btn.active .plus svg {
    transition: 0.3s linear;
    transform: rotate(0deg);
}

.card.accordion .btn .plus svg {
    transition: 0.3s linear;
    transform: rotate(90deg);
}

.card.accordion .btn.active .minus svg path {
    fill: var(--color-text-yellow);
}

.card.accordion .btn.active .plus svg path {
    fill: var(--color-text-yellow);
}

.card.accordion .btn .minus svg path {
    fill: var(--color-text-white);
}

.card.accordion .btn .plus svg path {
    fill: var(--color-text-white);
}
