@import 'src/styles/mixins';

.homepage .features-section {
  margin-top: -40px;
  margin-bottom: 200px;
  z-index: 2;

  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }
}

.features-item {
  height: 100%;
  width: 100%;
}

.features-item__content {
  padding-left: 154px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @include media-breakpoint-down(xxl) {
    padding-left: 115px;
  }
}

.features-item__title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: 1px;
  color: var(--color-light-yellow);
}

.features-item__text {
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.features-item--lock,
.features-item--earn,
.features-item--buy {
  .round-button {
    line-height: 42px;
    padding: 7px;
    width: 100%;

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.features-slider {
  display: flex;
  margin: auto;
  user-select: none;
  width: 100%;
}

.features-slider .swiper-slide {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 24px;
  padding: 31px;
  margin-right: 16px;
  margin-left: 16px;
  width: 400px;
  transition: opacity .15s linear;

  @include media-breakpoint-down(xxl) {
    width: 350px;
    margin-right: 12px;
    margin-left: 12px;
  }
}

.features-slider .swiper-slide.earn {
  background-image: url('../../../assets/features/earn-mwave-bg.png');
}

.features-slider .swiper-slide.buy {
  background-image: url('../../../assets/features/buy-token-bg.png');
}

.features-slider .swiper-slide.lock {
  background-image: url('../../../assets/features/lock-mwave-bg.png');
}
