.divider {
  background-color: var(--color-separator);
  border: 1px solid var(--color-separator);
  --divider-offset: 10px;


  &--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin-top: var(--divider-offset);
    margin-bottom: var(--divider-offset);
  }

  &--vertical {
    display: inline-block;
    width: 1px;
    height: 100%;
    margin-left: var(--divider-offset);
    margin-right: var(--divider-offset);
  }
}
