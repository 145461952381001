.approval-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@supports (gap: 8px) {
    .approval-steps {
        gap: 8px;
    }
}

@supports not (gap: 8px) {
    .approval-steps > * + * {
        margin-left: 8px;
    }
}

.approval-steps__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    color: var(--color-main-grey);
}

.approval-steps__label.active {
    color: var(--color-light-yellow);
}

.approval-steps__label:not(.active):first-child {
    color: var(--color-text-white);
}

.approval-steps__line {
    width: 200px;
    height: 4px;
    border-radius: 3px;
    background: linear-gradient(108.68deg, #FFCC7E 4.9%, #FFCC7E 34.14%, rgba(255, 204, 106, 0.5) 45%, rgba(255, 255, 255, 0) 100%);
}

.approval-steps__line.filled {
    background: linear-gradient(108.68deg, #FFCC7E 4.9%, #FFCC7E 54.14%, #e8b15c 70%);
}
