@import "src/styles/mixins";

.project-tag {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: var(--color-medium-black);
  text-transform: uppercase;
  backdrop-filter: blur(20px);
  font-size: 16px;
  font-weight: 600;
  line-height: 126%;
  letter-spacing: 0.5px;
  white-space: nowrap;

  &__image {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &.coming-soon {
    background: #FF833D;
  }

  &.registration-open {
    background: #06B883;
  }

  &.registration-closed {
    background: #0e7d96;
  }

  &.guaranteed-live {
    background: #EF8BFF;
  }

  &.fcfs-coming {
    background: #8729cf;
  }

  &.fcfs-live {
    background: #F05F56;
  }

  &.closed {
    background: #444B56;
  }

  @include media-breakpoint-down(xl) {
    &.badge {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
}
