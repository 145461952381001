$shadow-gradient: linear-gradient(180deg, rgba(30, 30, 47, 0) 0%, #1E1E2F 93.26%);

.saft-modal__dialog {
  min-height: unset;
  height: 85vh;
}

.saft-modal .close-button {
  appearance: none;
  position: absolute;
  right: 20px;
  top: 20px;
  outline: none;
  border: none;
  background: none;

  svg {
    height: 30px;
    width: 30px;

    path {
      fill: var(--color-main-grey)
    }
  }
}

.saft-modal .close-button:hover svg path {
  fill: var(--color-text-red);
}

.modal-header.saft-modal__header {
  justify-content: flex-start;
  border: none;
  padding: 0;
  margin-bottom: 32px;
}

.saft-modal__project-logo {
  width: 100px;
  height: 100px;
  margin-right: 24px;
}

@media (max-height: 850px) {
  .saft-modal__project-logo {
    width: 95px;
    height: 95px;
  }
}

.saft-modal__body.modal-body {
  padding: 0;
}

@media (max-width: 1199px) {
  .saft-modal__body h2.title {
    font-size: 32px;
  }
}

.saft-modal__project-name {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.26;
  margin: 0;
  color: var(--color-light-yellow);
}

.saft-content {
  margin-top: 32px;
  height: 32vh;
  padding-right: 20px;
  max-height: 583px;
  padding-bottom: 50px;
}

.saft-content ol {
  padding-left: 3rem;
}

.saft-modal h2.title {
  color: var(--color-text-white);
  font-size: 32px;
}

.saft-content h2 {
  font-size: 24px;
  margin-bottom: 18px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.saft-content p {
  letter-spacing: 0.5px;
}

.modal-footer.saft-modal__footer {
  border: none;
  flex-direction: column;
}

.saft-modal__footer .form-group {
  margin: 0;

  .form-check-label {
    font-weight: 500;
    letter-spacing: unset;
  }
}

.saft-modal__footer > div {
  margin: 10px 0;
}

.saft-modal__footer .round-button.transparent {
  background: none;
  text-transform: none;
  backdrop-filter: none;
  border-color: transparent;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 17px;
  height: unset;
}

.round-button.saft-modal__continue {
  width: 428px;
}

.saft-content.custom-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.saft-content.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.saft-content-shadow {
  position: absolute;
  width: 100%;
  height: 150px;
  z-index: 2;
  background: $shadow-gradient;
  left: 0;
  bottom: 0;
  pointer-events: none;
}
