$header-height: 92px;

.page-heading {
  padding: calc(#{$header-height} + 40px) 32px 40px;
  margin-top: -#{$header-height};
  min-height: 380px;
  background-image: var(--page-heading-banner);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    font-size: 24px;
    line-height: 30px;
  }

  &__body {
    margin-top: 24px;
  }
}
