@import "src/styles/mixins";

$focus-color: #0096FF;

.round-button {
  --button-size: 56px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--base-font);
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 20px;
  transition: 0.15s linear;
  border: 1px solid;
  height: var(--button-size, 56px);
  @include gap(8px);

  &:disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }

  &--with-badge:disabled {
    opacity: 1;
  }

  &.base {
    --button-size: 56px;
    border-radius: 12px;
  }

  &.xs {
    --button-size: 32px;
    font-family: var(--extra-font);
    font-size: 12px;
    line-height: 1.25;
    font-weight: 600;
    border-radius: 8px;
    letter-spacing: 1px;
    padding: 5px 16px;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  &.small {
    --button-size: 48px;
    border-radius: 12px;
  }

  &.large {
    --button-size: 68px;
    border-radius: 16px;
  }

  &.icon-button {
    padding: 5px;
    width: var(--button-size);

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }

  &.rounded-circle {
    border-radius: 50%;
  }

  &.icon-button.rounded-circle {
    border: none;
  }

  &.light {
    background-color: var(--color-main-yellow);
    color: var(--color-main-dark);
    border-color: var(--color-main-yellow);

    &:not(:disabled) {
      &:hover {
        background-color: var(--color-light-yellow);
        border-color: var(--color-light-yellow);
      }

      &:active {
        background-color: var(--color-light-black);
        border-color: var(--color-light-black);
        color: var(--color-light-yellow);
      }

      &:focus-visible {
        outline: 3px solid $focus-color;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &.dark {
    color: var(--color-light-yellow);
    border-color: var(--color-light-yellow);
    background-color: var(--color-medium-black);

    &:not(:disabled) {
      &:hover {
        color: var(--color-main-yellow);
        border-color: var(--color-main-yellow);
        background-color: var(--color-main-dark);
      }

      &:active {
        color: var(--color-main-yellow);
        border-color: var(--color-medium-black);
        background-color: var(--color-medium-black);
      }

      &:focus-visible {
        box-shadow: 0px 0px 8px $focus-color;
      }
    }

    &:disabled {
      color: var(--color-main-grey);
      border-color: var(--color-light-black);
      background-color: var(--color-light-black);
    }
  }

  &.transparent {
    color: var(--color-main-yellow);
    background: transparent;
    border-color: transparent;
    backdrop-filter: blur(40px);

    &:not(:disabled) {
      &:hover {
        color: var(--color-light-yellow);
      }

      &:active {
        color: var(--color-text-white);
      }

      &:focus-visible {
        outline: 3px solid $focus-color;
      }
    }


    &:disabled {
      opacity: 0.5;
    }
  }

  * + svg {
    margin-inline-end: 5px;
  }

  svg {
    width: 1em;
    height: 1em;

    path {
      fill: currentColor;
      transition: 0.15s;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  &__badge {
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 12px;
    line-height: 1.26;
    color: var(--color-background);
    padding: 6px 12px;
    background-color: var(--color-text-white);
    border: 2px solid var(--color-background);
    border-radius: 20px;
    text-transform: none;
    transform: rotate(-3.93deg);
  }

  .link-arrow {
    height: 1.2em;
    width: 1.2em;
  }

  &.wide {
    padding-left: 66px;
    padding-right: 66px;
  }

  &.loading {
    &:after {
      position: absolute;
      content: '';
      inset: 0;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: 1;
      border-radius: inherit;
    }

    .loader {
      position: absolute;
      z-index: 2;
      left: calc(50% - var(--loader-size) / 2);
      top: calc(50% - var(--loader-size) / 2);
      margin: 0;
    }
  }

  .loader {
    --loader-color: currentColor;
    --loader-size: 24px;
    display: inline-block;
    vertical-align: middle;
  }

  * + .loader {
    margin-left: 6px;
  }

  .loader:not(:last-child) {
    margin-right: 6px;
  }
}
