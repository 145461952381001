@import 'src/styles/mixins';

.hero-row {
  z-index: 100;
  height: 100%;

  @include media-breakpoint-down(sm) {
    margin-bottom: -100px;
  }
}

.hero-row [class*="col"] {
  z-index: 1;
}

.hero-row .hero-row__main {
  position: relative;


  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 72px;
    margin-bottom: -115px;
    height: 100%;
    margin-top: unset;
  }
}

.hero-row .title {
  font-size: 64px;
  font-weight: 600;
  color: #fff;
  position: relative;
  margin-bottom: 10px;
  z-index: 2;
}

.homepage .hero-row h1.title {
  font-size: 64px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 112%;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 112%;
  }
}

.hero-row .subtitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.5px;

  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.hero-row .hero-buttons {
  margin-top: 34px;
  position: relative;
  display: flex;

  align-items: stretch;
  z-index: 2;

  @include gap(8px);

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.hero-buttons__countdown .round-button {
  height: 68px;
}

.hero-buttons__whitelist-date {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.26;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-medium-black);
  padding-right: 19px;
  padding-left: 19px;
  border-radius: 12px;
  height: 68px;
  color: var(--color-main-yellow);
}

.hero-buttons__whitelist-date .campaign-date {
  overflow: hidden;
  line-height: 85%;
  margin: 0;
  padding: 0;
}

.campaign-date .month,
.campaign-date .day,
.campaign-date .year, {
  overflow: hidden;
  padding-right: 3px;
  padding-left: 3px;
  color: var(--color-main-yellow);
}

.campaign-date .month,
.campaign-date .day, {
  border-right: 1px solid var(--color-light-black);
}

.campaign-date .month .main-grey-text {
  font-weight: 400;
}

.hero-buttons .round-button.dark {
  @include blurred-dark-background(40px);
}

.discover-button {
  height: 68px;
}
