.loader {
    --loader-size: 60px;
    --loader-color: var(--color-main-yellow);
}

.lds-ring {
    display: block;
    position: relative;
    width: var(--loader-size);
    height: var(--loader-size);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--loader-size);
    height: var(--loader-size);
    border: 3px solid var(--loader-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--loader-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader.centered {
    margin: auto;
}
