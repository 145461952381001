@import "src/styles/mixins";

$stats-info-v-gap: 28px;
$stats-info-h-gap: 16px;

.project-stats {
  &.tile {
    padding: 40px;
  }

  &__status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: var(--color-light-yellow);

    @include media-breakpoint-down(xl) {
      margin-bottom: 16px;
    }
  }

  &__status h3.title {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__progress {
    margin-bottom: 16px;
  }

  &__progress .progress {
    background: rgba($color-main-yellow, 0.1);
    overflow: unset;
    backdrop-filter: blur(20px);
    border-radius: 24px;
  }

  &__progress .progress-bar {
    background: var(--color-main-yellow);
    border-radius: 10px;
  }

  &__progress .percentage {
    display: block;
    text-align: right;
    font-size: 20px;
    line-height: 1.26;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 7px;
    min-height: 0.5em;
  }

  &__list {

    .name {
      color: var(--color-main-grey);
      font-weight: 500;
    }

    .value {
      white-space: nowrap;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    @include gap($stats-info-v-gap $stats-info-h-gap);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.26;
    letter-spacing: 0.5px;
    margin-top: 24px;

    @include media-breakpoint-down(xxl) {
      font-size: 14px;
    }

    dt {
      font-weight: inherit;
      color: var(--color-main-grey);
    }

    dd {
      margin-bottom: 0;
    }
  }
}


.stats-block {
  $p: &;
  position: relative;
  padding: 16px;
  text-align: center;
  flex-basis: calc(50% - #{$stats-info-h-gap} / 2);
  border-radius: 16px;
  background-color: var(--color-medium-black);

  &__badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 24px;
    top: -12px;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 1.26;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: normal;
    color: var(--color-main-dark);
    background-color: var(--color-text-white);

    &:empty {
      display: none;
    }
  }

  &.active {
    #{$p}__badge {
      background-color: var(--color-main-yellow);
    }
  }

  &.completed {
    #{$p}__badge {
      background-color: var(--color-main-grey);
    }
  }

  dt {
    margin-bottom: 6px;
  }

  &--double {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 19px 16px 16px;
    position: relative;
    @include gap(8px);

    .separator {
      position: absolute;
      height: 100%;
      left: calc(50% - 4px);
      width: 8px;
      top: 0;
      margin: 0;
      background-color: var(--tile-background-color);
      transition: .2s linear;
    }

    > div:not(.separator) {
      flex-grow: 1;
      flex-basis: calc(50% - #{$stats-info-h-gap} / 2);

      &:last-of-type {
        padding-left: 12px;
      }
    }
  }
}
