@import "src/styles/mixins";

.heat-row .color-strip {
    background: var(--color-main-yellow);
    border-radius: 110px;
    height: 30px;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.color-strip__overlay {
    background: var(--color-main-dark);
    height: 100%;
}

.heat-row .progress {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: unset;
    margin-top: -45px;
}

.heat-row .progress .item {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 6px;
    text-align: center;
    position: relative;
}

.heat-row .progress .item:first-child {
    flex-grow: 0.1;
    flex-shrink: 1;
    text-align: left;
}

.heat-row .progress .item svg {
    @include blurred-dark-background(40px);
    padding: 10px;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
}

.heat-row .progress .item svg path {
    fill: var(--color-light-yellow);
}

.heat-row .progress .item .description {
    margin-top: 1rem;
    margin-bottom: 0;
    background: var(--color-main-dark);
    backdrop-filter: blur(60px);
    border-radius: 16px;
    padding: 16px 16px 30px;
}

.heat-row .progress .item p {
    margin: 0;
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 1px;
    color: var(--color-main-grey);
}

.heat-row .progress .item .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;
    color: var(--color-main-yellow);
}

.heat-row .progress .item:first-child .title {
    margin-top: 1.25rem;
    text-align: left;
    color: var(--color-main-dark);
}

.heat-row .progress .item .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    margin-bottom: 1rem;
}

.heat-row .progress .item .title span {
    font-weight: 500;
    font-size: 10px;
    line-height: 126%;
    letter-spacing: 1px;
    display: block;
    padding-top: 5px;
}
