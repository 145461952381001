@import "../../../styles/mixins";

.launch-modal .back-link {
  position: absolute;
  top: 20px;
  left: 50px;
  padding: 0;
  backdrop-filter: unset;
}

.launch-modal .close-button {
  appearance: none;
  position: absolute;
  right: 20px;
  top: 20px;
  outline: none;
  border: none;
  background: none;

  &:hover svg path {
    fill: var(--color-text-red);
  }
}

.launch-modal__body {
  .marketing-page-form__inner {
    padding: 0;
    margin: 0;

    .form-label {
      letter-spacing: unset;
    }
  }

  .marketing-page-form.tile {
    padding: 0;
    margin: 0;
    max-width: unset;
    box-shadow: unset;
  }

  .row {
    --bs-gutter-x: 0;
  }

  .form-row {
    font-size: 24px;
    font-weight: 600;
  }
}

.modal-header.launch-modal__header {
  align-items: flex-start;
  border: none;
  margin-bottom: 0;
  flex-direction: column;
  margin-top: 30px;

  p {
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }
}

.launch-modal__project-name {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.26;
  color: var(--color-light-yellow);
  margin: 0 0 8px;
}

.modal-footer.launch-modal__footer {
  border: none;
  flex-direction: column;
}

.launch-radio-container {
  text-align: left;
  margin-bottom: 24px;

  p {
    font-weight: 500;
    color: var(--color-main-grey);
    margin-bottom: 8px;
  }

  .launch-radio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-radius: 16px;
    background-color: var(--color-medium-black);
    padding: 4px;
    @include gap(4px);

    .form-group.form-group--radio {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      font-size: 16px;
      line-height: 1;
      padding: 0;
      font-weight: 500;
      border-radius: 12px;
      width: 33%;
      margin: 0 !important;

      .form-check {
        width: 100%;

        label {
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.active {
        font-weight: 700;
        color: var(--color-main-yellow);
        background-color: var(--color-light-black);
      }
    }
  }
}

.thanks-for-inquiring {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 15px;
  }

  p {
    letter-spacing: 0.5px;
  }
}
