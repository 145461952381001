@import "variables";

@mixin blurred-dark-background($length, $position: relative, $opacity: 0.15) {
  @supports (backdrop-filter: blur($length)) {
    background-color: rgba(255, 255, 255, $opacity);
    backdrop-filter: blur($length);
  }

  @supports not (backdrop-filter: blur($length)) {
    position: $position;
    background-color: rgba(43, 43, 43, 0.9);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      filter: blur($length);
      background-color: inherit;
      z-index: -1;
    }
  }
}

@mixin media-breakpoint-up($name) {
  $bp: map-get($grid-breakpoints, $name);
  @if $bp {
    @media (min-width: $bp) {
      @content;
    }
  } @else {
    @media (min-width: $name) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($name) {
  $bp: map-get($grid-breakpoints, $name);
  @if $bp {
    @media (max-width: $bp - 1px) {
      @content;
    }
  } @else {
    @media (max-width: $name - 1px) {
      @content;
    }
  }
}

@mixin media-breakpoint-between($min-bp, $max-bp) {
  $min: map-get($grid-breakpoints, $min-bp);
  $max: map-get($grid-breakpoints, $max-bp);

  @if $min and $max {
    @media (min-width: $min) and (max-width: $max - 1px) {
      @content;
    }
  } @else if $min {
    @media (min-width: $min) and (max-width: $max-bp - 1px) {
      @content;
    }
  } @else if $max {
    @media (min-width: $min-bp) and (max-width: $max - 1px) {
      @content;
    }
  } @else {
    @media (min-width: $min-bp) and (max-width: $max-bp - 1px) {
      @content;
    }
  }
}

@mixin gap($pixels) {
  @supports (gap: $pixels) {
    gap: $pixels;
  }

  @supports not (gap: $pixels) {
    & > * + * {
      margin-left: $pixels;
    }
  }
}

@mixin fadingHidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}
