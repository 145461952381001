@import 'src/styles/mixins';

.tiers-section {
  margin-bottom: 200px;
}

.tiers-section .title {
  margin-bottom: 40px;

  @include media-breakpoint-down(lg) {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
