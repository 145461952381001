.page-heading.about-us {
  min-height: 580px;

  .page-heading__inner {
    height: 340px;
    justify-content: flex-end;
  }

  .title {
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
  }
}

.about-us-caption {
  margin-bottom: 160px;
}

.about-us-caption p {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.6;
  max-width: 90%;
}

.team {
  margin-top: 80px;
}

.team .container {
  max-width: 920px;
}

.team__heading {
  margin-bottom: 40px;
  text-align: center;
}

.team__heading .title {
  margin-bottom: 24px;
}

.team__heading p {
  line-height: 1.6;
  letter-spacing: 0.5px;
}

.team__list {
  margin-top: 24px;
  margin-bottom: 48px;
}

.partnerships-about-us {
  background: var(--color-medium-black);
  height: 724px;
  display: flex;
  align-items: center;

  .title {
    margin-bottom: 40px;
  }
}

.partnerships-about-us .partnerships-col {
  display: flex;
  align-items: center;
}

.investors {
  padding: 200px 0;
}

.investors .title {
  margin-bottom: 69px;
}

.add-investor-card {
  padding: 16px 0;
  text-align: center;
}

.add-investor-card__image-box {
  width: 160px;
  height: 160px;
  margin: 0 auto 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
}

.add-investor-card__text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.partnerships-col svg path {
  fill: var(--color-main-yellow)
}
