@import 'src/styles/mixins';

.faq-section {
  margin-bottom: 200px;

  .round-button {
    margin-top: 40px;
    display: inline-flex;
  }
}

.faq-column .title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 112%;
  margin-bottom: 26px;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

.faq-column .card.accordion {
  padding-top: 25px;
  border-bottom: 1px solid var(--color-light-black);
  border-radius: 0;
}

.faq-column .card.accordion .card-header {
  padding-bottom: 25px;
  position: relative;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    max-height: 80px;
  }
}

.faq-column .card.accordion .card-body {
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .5px;

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

.faq-column .card.accordion .btn {
  font-size: 24px;
  padding: 0;
  text-align: left;
  letter-spacing: 1px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.faq-column .card.accordion svg {
  @include media-breakpoint-down(sm) {
    width: 13px;
  }
}
