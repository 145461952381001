@import "src/styles/mixins";

.account-staking,
.account-lockup,
.portfolio-page {
  padding: 80px 0;
}

.account-page {
  text-align: left;

  .heat-row {
    margin: 40px 0 0;
  }

  .account-nav {
    border: none;
    border-radius: 16px;
    background-color: var(--color-medium-black);
    padding: 4px;
    @include gap(4px);

    .nav-link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: none;
      font-size: 16px;
      line-height: 1;
      padding: 16px;
      font-weight: 500;
      width: 180px;
      border-radius: 12px;

      &.active {
        font-weight: 700;
        color: var(--color-main-yellow);
        background-color: var(--color-light-black);
      }
    }
  }
}

.kyc-section .kyc-badge__status p {
  font-size: 32px;
}

.stake-allocation-section .title {
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 50px;
}

.stake-allocation-section .bottom-description {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.5px;
}

.stake-allocation-section .ellipse18 {
  left: 8%;
  top: 50px;
}

.non-polygon-block, .non-polygon-block.col {
  max-width: 700px;
  margin: 50px auto;
  padding: 40px 25px;
}

.stake-block.row {
  margin-top: 40px;
  margin-bottom: 80px;
  align-items: flex-start;
}

.stake-form {
  display: flex;
  flex-direction: column;
  border-radius: 70px;
  padding: 40px;
}

.stake-form .tab-content {
  flex: 1 0;
}

.stake-form__input-icon {
  height: 48px;
}

.stake-block .input-group {
  margin-bottom: 26px;

  .form-control {
    text-align: left;
    margin-left: 8px;
  }
}

.stake-block__buttons {
  display: flex;
  width: 100%;
  margin: 0 0 5px;
}

@supports (gap: 16px) {
  .stake-block__buttons {
    gap: 16px;
  }
}

@supports not (gap: 16px) {
  .stake-block__buttons > * + * {
    margin-left: 16px;
  }
}

.stake-block__buttons .round-button {
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0;
}

.stake-form .form-message {
  margin-bottom: 17px;
}

@media (max-width: 1199px) {
  .account-nav .nav-link {
    font-size: 28px;
  }

  .stake-form {
    padding: 24px;
    border-radius: 50px;
  }

  .stake-block .nav-tabs .nav-link {
    margin-right: 16px;
    font-size: 24px;
  }
}
