.back-link {
  $p: &;
  display: inline-flex;
  text-transform: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  &.round-button {
    color: var(--color-light-yellow);
  }

  &:hover {
    #{$p}__label {
      color: var(--color-light-yellow);
    }
  }

  &__label {
    line-height: 32px;
    transition: 0.2s linear;
    color: var(--color-main-grey);
  }
}
