.projects-page {

  .title:first-child {
    position: relative;
  }

  & > section[class^="projects"] {
    padding: 80px 0;
  }

  .projects-completed {
    margin-bottom: 120px;
  }
}
