.marketing-content {
  margin-top: 80px;
  margin-bottom: 200px;
}

.kyc-heading {
  .page-heading__inner {
    justify-content: flex-end;
    height: 155px;
  }
}

.kyc-page__caption {
  margin-bottom: 32px;
}

.kyc-page__caption p {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-light-yellow);
}

.kyc-page__requirements h3.title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.kyc-page__requirements .title {
  width: 40%;
  margin-right: 40px;
}

.kyc-page__requirements .description {
  display: flex;
}

.kyc-page__requirements ul {
  margin-bottom: 40px;
}

.kyc-page__requirements li {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.kyc-page__requirements .kyc-badge__status p {
  font-size: 32px;
}
