@import "src/styles/mixins";

.footer {
  position: relative;
  padding: 3rem 0;
  background-color: var(--color-main-dark);
  z-index: 1;

  @include media-breakpoint-down(xl) {
    padding: 2rem 0;
    margin-top: 0;
  }

  .doge {
    position: absolute;
    bottom: 0;
    left: 73%;
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__list {
    padding-right: 40px;

    @include media-breakpoint-down(xxl) {
      padding: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  &__logo img {
    width: 240px;
    height: 65px;
  }

  &__list-item,
  &__list-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  &__list-title {
    line-height: 20px;
    letter-spacing: 1px;
    color: var(--color-main-grey);
  }

  &__list-item {
    line-height: 18px;
    letter-spacing: 0.3px;
  }

  &__socials {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    @include gap(16px);

    svg path {
      transition: .2s linear;
      fill: var(--color-light-yellow);
    }

    a:hover svg path {
      fill: var(--color-main-yellow);
    }
  }
}

.row.copyrights-row {
  margin-top: 20px;
}

.footer-copyrights {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: var(--color-main-grey);
}

