.get-ready-badge.tile {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../../assets/metaverse-microwave.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
}

.get-ready-badge .tile__main {
    position: absolute;
    top: 180px;
    font-size: 64px;
    line-height: 112%;
    margin-bottom: 40px;
    text-align: center;
}

.get-ready-badge .round-button {
    position: absolute;
    bottom: 100px;
}
