@import "src/styles/mixins";

.navbar {
  padding-inline: 25px;
}

.navbar-brand {
  flex-basis: 35%;
  flex-grow: 1;

  svg {
    height: 64px;
  }
}

header .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  font-family: var(--base-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.005em;
  color: var(--color-text-white);
  border-radius: 8px;
  padding: 5px 12px;

  &:hover {
    color: var(--color-text-yellow) !important;
  }

  &.active {
    background: var(--color-medium-black);
    color: var(--color-main-yellow);
  }

  @include media-breakpoint-down(xl) {
    padding: 0.5rem;
  }

  svg {
    width: 10px;
    height: 10px;
    margin-left: 6px;

    path {
      fill: var(--color-light-yellow);;
    }
  }
}

.center-links {
  justify-content: center;
  margin: auto;
}

.header .network-switcher__toggle {
  margin-right: 5px;
}

.header .round-button.header__nav-button {
  text-transform: capitalize;
  font-weight: 500;
}

.header .container-fluid {
  padding: 0 32px;
}

.navbar-collapse {
  @include gap(16px);
}

.nav-dropdown .round-button::after {
  transition: 0.3s linear;
  border: none;
  width: 8px;
  margin-left: 8px;
  height: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/arrow.svg');
}

.nav-dropdown.show .round-button::after {
  transform: rotate(180deg);
}

.nav-dropdown.products .round-button::after {
  display: none;
  border-radius: 50%;
}

.nav-dropdown.products .product-description {
  display: flex;
  margin-left: 15px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.nav-dropdown.products .nav-dropdown__item.dropdown-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header__nav-button.round-button svg {
  margin: 0;
  width: 32px;
  height: 32px;
}

.nav-dropdown.products.show .round-button svg circle,
.nav-dropdown.products.show .round-button svg ellipse {
  fill: var(--color-main-yellow);
}

.nav-dropdown.products .round-button {
  border: none;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-dropdown__menu.dropdown-menu {
  background: var(--color-medium-black);
  border-radius: 16px;
  min-width: 184px;
  top: calc(100% + 12px);
  z-index: 2;
  padding: 16px 8px;
}

.nav-dropdown__item.dropdown-item {
  color: var(--color-light-yellow);
  padding: 0.65rem 1rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.nav-dropdown__item.dropdown-item .description {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--color-text-white);
}

.nav-dropdown__item.dropdown-item .text-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-dropdown__item.dropdown-item .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--color-light-yellow);
  margin-bottom: 8px;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;

  .link-arrow {
    visibility: hidden;
  }
}

.nav-dropdown__item.dropdown-item:hover,
.nav-dropdown__item.dropdown-item.active,
.nav-dropdown__item.dropdown-item:active {
  background-color: var(--color-light-black);
  color: var(--color-text-white);
  border-radius: 8px;

  .title {
    color: var(--color-text-yellow);
  }

  .link-arrow {
    visibility: visible;
  }
}

.nav-dropdown__menu .dropdown-item-text {
  color: var(--color-text-white);
  padding: 0.65rem 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.network-switcher__dropdown .round-button.header__connect-wallet {
  display: contents;
}

.network-switcher__dropdown .round-button::after {
  transition: 0.3s linear;
  border: none;
  width: 8px;
  height: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('../../../assets/arrow.svg');
  align-items: center;
}

.network-switcher__dropdown.show .round-button::after {
  transform: rotate(180deg);
}

.header__connect-wallet span {
  background-color: var(--color-medium-black);
  backdrop-filter: blur(40px);
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px 6px 9px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  font-weight: 500;
  color: var(--color-text-white);
}

.navbar-expand .navbar-collapse.account {
  flex-basis: 35%;
  flex-grow: 1;
}

